import React, { Component } from 'react';
import Page from './page';


class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transparent: props.transparent
        };
    }

    render() {
        return (
            <Page state={this.state}  />
        );
    }
}
export default Footer;