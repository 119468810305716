import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import UsersActions from '../../redux/actions/Users';
import { withRouter } from 'react-router-dom';

const Profile = ({ users, getMember, setUpdateMember, setChangePassword }) => {

    const [form, setForm] = useState([]),
        [errors, setErrors] = useState([])

    useEffect(() => {
        if (users.selectedUser === null) {
            getMember(users.authUser.user_id)
        }
    }, [])

    useEffect(() => {
        if (users.selectedUser) {
            setForm({
                full_name: users.selectedUser.full_name,
                username: users.selectedUser.username,
                identification_card: users.selectedUser.identification_card,
                mobile: users.selectedUser.mobile,
                phone: users.selectedUser.phone,
                vimeo_code: users.selectedUser.vimeo_code,
            })
        }
    }, [users.selectedUser]);

    const
        /**
        * Handled update user by uid
        */
        handledUpdate = () => {
            if (handleValidate("profile")) {
                setUpdateMember(users.authUser.user_id, {
                    full_name: form.full_name,
                    identification_card: form.identification_card,
                    phone: form.phone,
                    mobile: form.mobile
                })
            }
        },

        /**
         * Handled update password by user id
         */
        handledUpdatePassword = () => {
            if (handleValidate("password")) {
                setChangePassword(users.authUser.user_id, form, () => {
                    setForm({ ...form, password: "", new_password: "", rep_new_password: "" })
                })
            }
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = (type) => {
            setErrors({})
            if (type === "profile") {
                if (!form.full_name) {
                    setErrors({ full_name: true });
                    return false;
                }
            } else {
                if (!form.password) {
                    setErrors({ password: true });
                    return false;
                }

                if (!form.new_password) {
                    setErrors({ new_password: true });
                    return false;
                }

                if (!form.rep_new_password) {
                    setErrors({ rep_new_password: true });
                    return false;
                }

                if (form.rep_new_password !== form.new_password) {
                    setErrors({ rep_new_password: true });
                    return false;
                }
            }
            return true;
        };

    return (
        <Page form={form} setForm={setForm} errors={errors} handledUpdate={handledUpdate} handledUpdatePassword={handledUpdatePassword} />
    );
}

const mapStateToProps = ({ users }) => ({
    users
});

const mapDispatchToProps = () => ({
    ...UsersActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Profile));