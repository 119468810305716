import { confirmAlert } from "react-confirm-alert";

/**
 * Currency text to currency format
 * @param {*} n 
 * @param {*} currency 
 * @returns 
 */
export const infoAlert = (title, body) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h3><i className="fas fa-exclamation-circle"></i>&nbsp;{title}</h3>
                    <p>{body}</p>
                    <div className="text-center">
                        <button className="btn btn-secondary" onClick={onClose}><i className="fas fa-times"></i>&nbsp;Cerrar</button>
                    </div>
                </div>
            )
        }
    });
}
