import React from "react";
import "./style.css";
import Header from "../../modules/page/header";
import Footer from "../../modules/page/footer";
import { Col, Container, Button, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function Page(props) {

  const { rows, config, handledVote, users, handledToSelect, modal, setModal, handledSelectOpt, list } = props

  return (
    <>
      <Header></Header>
      <section id="vt-top">
        <Container>
          <ul className="nav-bar">
            <li><Link to="/">Home</Link> <span>&gt;</span> <Link to="#">Votación Silla {new Date().getFullYear()}</Link> <span>&gt;</span> <Link to="#">Votaciones</Link></li>
          </ul>
          <div>
            <h2 className="title-page">Bienvenidos a las votaciones para los Premios La Silla {new Date().getFullYear()}</h2>
            <p className="decription">Nominaciones oficiales de la industria cinematográfica</p>
          </div>
          {/*}
          <Image src={"../assets/la-silla.webp"} style={{ width: "100%" }} />
          {*/}
        </Container>
      </section>
      <section id="vt-nominations">
        <Container>
          {(parseInt(config.votes) && users.authUser) ?
            <>
              <Row>
                {
                  rows.map((row, key) => {
                    return (<Col xs={12} md={6} lg={4} key={key}>
                      <div className="card-nomination" onClick={() => handledToSelect(row)}>
                        <h2 className="title-page">{row}</h2>
                      </div>
                    </Col>)
                  })
                }
              </Row>
            </> :
            <div className="closed-voting">
              <p>{(!users.authUser) ? "Debe ser miembro para poder realizar votaciones" : "Las votaciones están cerradas por el momento"}</p>
            </div>
          }
        </Container>
      </section>

      <Modal show={modal.active} backdrop="static" keyboard={false} id="md-nomination">
        <Modal.Header>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {parseInt(config.step) === 1 && <p className="options-check">Solo puede selecionar 5 opciones</p>}
          <ul>
            {
              list.map((val, idx) => {
                return (
                  ((parseInt(config.step) === 2 && parseInt(idx) <= (modal.title === "Mejor Director" ? 5 : 4)) || parseInt(config.step) === 1) &&
                  <li key={idx}>
                    <p>
                      <strong>{val.movie.toUpperCase()}</strong>
                      <label style={{ textTransform: "capitalize" }}>{val.actor.toLowerCase()}</label>
                    </p>
                    <div className="checkbox-wrapper">
                      <label>
                        <input type="checkbox" disabled={val.disabled || val.vote} checked={val.isChecked} onClick={() => handledSelectOpt(val)} />
                      </label>
                    </div>
                  </li>)
              })
            }
          </ul>
          {parseInt(config.step) === 1 && <p className="options-check">Solo puede selecionar 5 opciones</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal({ ...modal, active: false })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" disabled={modal?.disabledVote} onClick={handledVote}><i className="far fa-paper-plane"></i>&nbsp;&nbsp;Enviar</Button>
        </Modal.Footer>
      </Modal>
      <Footer></Footer>
    </>
  )
}

export default Page