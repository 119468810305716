import React, { useEffect, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import PositionsActions from '../../redux/actions/Positions';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Positions = ({ positions, getPositions, getPosition, setPosition, setUpdate, setRemove, setClearPosition }) => {

    const [show, setShow] = useState({ add: false, edit: false }),
        [pid, setPid] = useState(null),
        [form, setForm] = useState({ active: 1 }),
        [errors, setErrors] = useState({})

    useEffect(() => {
        getPositions()
    }, []);

    useEffect(() => {
        if (positions.selected) {
            setForm({
                name: positions.selected.name,
                active: parseInt(positions.selected.active)
            })
            setShow({ ...show, edit: true })
        }
    }, [positions.selected]);

    useEffect(() => {
        if(!show.add && !show.edit) {
            setClearPosition()
            setForm({ name: "", active: 1 })
        }
    }, [show.edit, show.add]);

    const

        /**
         * Handled add new position
         */
        handledAdd = () => {
            if (handleValidate()) {
                setPosition(form)
                setShow({ ...show, add: false })
            }
        },

        /**
         * Handled update position by pid
         */
        handledUpdate = () => {
            if (handleValidate()) {
                setUpdate(pid, form)
                setShow({ ...show, edit: false })
            }
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (pid) => {
            setPid(pid)
            getPosition(pid)
        },

        /**
         * Handled remove position
         */
        handledRemove = (pid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Posición</h3>
                            <p>¿Realmente deseas eliminar este posición?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemove(pid, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.name) {
                setErrors({ name: true });
                return false;
            }
            return true;
        };

    const columns = [
        {
            name: 'ID', selector: row => row.position_id, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.position_id}</span>
            )
        }, {
            name: 'Nombre', selector: row => row.name, minWidth: '60%', maxWidth: '60%', sortable: true, cell: row => (
                <span>{row.name}</span>
            )
        }, {
            name: 'Estado', selector: row => row.active, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span style={{ color: ((parseInt(row.active)) ? "#31821b" : "rgb(200, 55, 55)") }}>{(parseInt(row.active)) ? "Activo" : "Desactivo"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY")}</span>
            )
        }, {
            name: '', minWidth: '8%', maxWidth: '8%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Editar Usuario"
                        style={{ margin: "0 auto" }} onClick={(uid) => handledShowEdit(row.position_id)}><i className="far fa-edit pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Eliminar Usuario"
                        style={{ margin: "0 auto" }} onClick={() => handledRemove(row.position_id)}><i className="far fa-trash-alt pointer"></i></button>
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} positions={positions} show={show} setShow={setShow} form={form} errors={errors}
            setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} handledUpdate={handledUpdate} />
    );
}

const mapStateToProps = ({ positions }) => ({
    positions
});

const mapDispatchToProps = () => ({
    ...PositionsActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Positions));