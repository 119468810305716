export const users = {
    GET_USER: 'users/GET_USER',
    AUTH: 'users/AUTH',
    GET_USERS: 'users/GET_USERS',
}

export const general = {
    GET_VOTES: 'general/GET_VOTES',
    PETITIONS: 'general/PETITIONS',
    LOADING: 'general/LOADING',
    CONFIG: 'general/CONFIG',
    VOTES: 'general/VOTES'
}

export const positions = {
    GET_POSITION: 'positions/GET_POSITION',
    GET_POSITIONS: 'positions/GET_POSITIONS'
}

export const vimeo = {
    GET_VIMEO: 'vimeo/GET_VIMEO',
    GET_VIMEOS: 'vimeo/GET_VIMEOS'
}

export const vimeoCode = {
    GET_VIMEOCODE: 'vimeoCode/GET_VIMEOCODE',
    GET_VIMEOSCODES: 'vimeoCode/GET_VIMEOSCODES'
}

export const nomineeRow = {
    GET_NOMINEE_ROW: 'nomineeRow/GET_NOMINEE_ROW',
    GET_NOMINEE_ROWS: 'nomineeRow/GET_NOMINEE_ROWS'
}

export const movies = {
    GET_MOVIE: 'movies/GET_MOVIE',
    GET_MOVIES: 'movies/GET_MOVIES'
}

export const nominees = {
    GET_NOMINEE: 'nominees/GET_NOMINEE',
    GET_NOMINEES: 'nominees/GET_NOMINEES'
}

const exportConst = {
    nomineeRow,
    positions,
    nominees,
    general,
    vimeoCode,
    movies,
    vimeo,
    users,
}

export default exportConst