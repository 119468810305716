import React from "react"
import "./style.css"
import Header from "../../modules/page/header"
import Footer from "../../modules/page/footer"
import { Button, Form } from "react-bootstrap"
import { Link } from "react-router-dom"

function Page(props) {

  const { handledLogin, form, setForm, errors } = props

  return (
    <>
      <Header></Header>
      <section id="login">
        <div className="div-login">
          <Form>
            <h5>Iniciar Sesión</h5>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control type="email" placeholder="Escriba su correo electrónico..." style={{ borderColor: (errors.username) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                onChange={(e) => setForm({ ...form, username: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="Escriba su contraseña..." style={{ borderColor: (errors.password) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                onChange={(e) => setForm({ ...form, password: e.target.value })} />
            </Form.Group>
            <Button variant="primary" onClick={handledLogin}><i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Iniciar Sesión</Button>
            <Link className="forget-password" to="/forget-password">¿Olvidaste tu contraseña?</Link>
          </Form>
        </div>
      </section>
      <Footer></Footer>
    </>
  )
}

export default Page