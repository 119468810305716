import React, { useEffect, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import VimeosActions from '../../redux/actions/Vimeo';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Vimeo = ({ vimeo, getVimeos, getVimeo, setVimeo, setUpdate, setRemove, setClearVimeo }) => {

    const initForm = { vimeoId: "", title: "", thumbnail: "" },
        [show, setShow] = useState({ add: false, edit: false }),
        [pid, setPid] = useState(null),
        [form, setForm] = useState(initForm),
        [errors, setErrors] = useState({}),
        [video, setVideo] = useState({ view: false, code: "" })

    useEffect(() => {
        getVimeos()
    }, []);

    useEffect(() => {
        if (vimeo.selected) {
            setForm({
                title: vimeo.selected.title
            })
            setShow({ ...show, edit: true })
        }
    }, [vimeo.selected]);

    useEffect(() => {
        if (!show.add && !show.edit) {
            setClearVimeo()
            setForm({ name: "", active: 1 })
        }
    }, [show.edit, show.add]);

    const

        /**
         * Handled add new position
         */
        handledAdd = () => {
            if (handleValidate()) {
                setVimeo(form)
                setShow({ ...show, add: false })
            }
        },

        /**
         * Handled update position by pid
         */
        handledUpdate = () => {
            if (handleValidate('edit')) {
                setUpdate(pid, form)
                setShow({ ...show, edit: false })
            }
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (pid) => {
            setPid(pid)
            getVimeo(pid)
        },

        /**
         * Handled view video
         */
        handledShowView = (code) => {
            setVideo({ view: true, code })
        },

        /**
         * Handled remove position
         */
        handledRemove = (pid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Vimeo</h3>
                            <p>¿Realmente deseas eliminar este vimeo?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemove(pid, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = (type = "add") => {
            setErrors({})
            if (!form.title) { setErrors({ title: true }); return false; }
            else if (!form.vimeoId && type !== "edit") { setErrors({ vimeoId: true }); return false; }
            else if (!form.thumbnail) { setErrors({ thumbnail: true }); return false; }
            return true;
        };

    const columns = [
        {
            name: 'Code', selector: row => row.code, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.code}</span>
            )
        }, {
            name: 'Título', selector: row => row.title, minWidth: '60%', maxWidth: '60%', sortable: true, cell: row => (
                <span>{row.title}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
            )
        }, {
            name: '', minWidth: '10%', maxWidth: '10%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>
                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Ver Vimeo"
                        style={{ margin: "0 auto" }} onClick={(uid) => handledShowView(row.code)}><i className="fas fa-play pointer"></i></button>
                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Editar Vimeo"
                        style={{ margin: "0 auto" }} onClick={(uid) => handledShowEdit(row.vimeo_id)}><i className="far fa-edit pointer"></i></button>
                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Eliminar Vimeo"
                        style={{ margin: "0 auto" }} onClick={() => handledRemove(row.vimeo_id)}><i className="far fa-trash-alt pointer"></i></button>
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} vimeo={vimeo} show={show} setShow={setShow} form={form} errors={errors}
            setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} handledUpdate={handledUpdate} video={video} setVideo={setVideo} />
    );
}

const mapStateToProps = ({ vimeo }) => ({
    vimeo
});

const mapDispatchToProps = () => ({
    ...VimeosActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Vimeo));