import React from "react"
import "./style.css"
import Header from "../../modules/page/header"
import Footer from "../../modules/page/footer"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

function Page(props) {

  const { handledGenerateCode, form } = props


  return (
    <>
      <Header></Header>
      <section id="generate-code">
        <div>
          <h5>Genera código aleatorio</h5>
          {
            form?.code && form?.login &&
            <>
              <p>Su usuario ya tiene un código generado</p>
              <p>Código: <strong>{form?.code}</strong></p>
              <Link className="link-movies" to="/player">
                <i className="fas fa-link"></i>&nbsp;&nbsp;Ver Películas
              </Link>
            </>
          } {
            !form?.code && form?.login &&
            <>
              <p>Genera un código para poder reproducir las películas nominadas antes de tomar tu decisión en la votación.</p>
              <Button variant="primary" onClick={handledGenerateCode}><i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Generar Código</Button>
            </>
          } {
            !form?.login &&
            <>
              <p>Debe tener una sesión iniciada para poder generar un código en su cuenta.</p>
            </>
          }
        </div>
      </section>
      <Footer></Footer>
    </>
  )
}

export default Page