import { vimeoCode } from '../constants/ActionTypes';

const INITIAL_STATE = {
    list: [],
    selected: null
}

const vimeosCodesActionsFunc = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case vimeoCode.GET_VIMEOSCODES: {
            return { ...state, list: action.payload }
        }
        case vimeoCode.GET_VIMEOCODE: {
            return { ...state, selected: action.payload }
        }
        default:
            return state;
    }
}

export default vimeosCodesActionsFunc