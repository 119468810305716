import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NomineesActions from '../../redux/actions/Nominees';
import GeneralActions from '../../redux/actions/General';

const Votes = ({ users, nominees, setVote, getVoteByUser, general, getConfig, getNomineesByUid }) => {

    const initModal = { title: "", active: false, disabledVote: true },
        [rows, setRows] = useState([]),
        [list, setList] = useState([]),
        [modal, setModal] = useState({ title: "", active: false, disabledVote: true }),
        [votesOptions, setVotesOptions] = useState([]),
        [config, setConfig] = useState({ step: 0, votes: 0 })

    useEffect(() => {
        if (users.authUser) {
            getNomineesByUid(users.authUser.user_id)
            getConfig()
        }
    }, [users.authUser, getNomineesByUid, getConfig])

    useEffect(() => {
        if (!modal.active) setModal(initModal)
    }, [modal.active])

    useEffect(() => {
        if (nominees.list) {
            let list = []
            nominees.list.forEach((row, key) => {
                let options = []
                nominees.list.forEach((val) => {
                    if (val.nominee_row === row.nominee_row) {
                        options.push({
                            id: val.nominated_id,
                            movie: val.movie,
                            actor: val.actor
                        })
                    }
                })
                list.push(row.nominee_row)
            })
            list.sort((a, b) => a.replace("Mejor") > b.replace("Mejor"))
            setRows([... new Set(list)])
        }
    }, [nominees.list])

    useEffect(() => {
        if (general.config) {
            setConfig(general.config)
            if (users.authUser) {
                getVoteByUser(users.authUser.user_id, general.config.step)
            }
        }
    }, [general.config])

    useEffect(() => {
        setVotesOptions(general.votes)
    }, [general.votes])

    useEffect(() => {
        if (list.length) {
            let count = 0, limitMax = (parseInt(config.step) === 2) ? 1 : 5, limitMin = (parseInt(config.step) === 2) ? 1 : 4
            for (let i = 0; i < list.length; i++) if (list[i].isChecked) count++;
            setModal({ ...modal, disabledVote: !(count >= (list.length > 5 ? limitMax : limitMin) && list.filter(r => r.vote).length <= 0) })
            const lst = list.map((row) => ({ ...row, votes: parseInt(row.votes), disabled: (count >= (list.length > 5 ? limitMax : limitMin) && !row.isChecked) ? true : false }))
            setList(lst)
        }
    }, [list])


    /**
     * Handled vote by user
     */
    const handledVote = async () => {
        setModal({ ...modal, active: false })
        const lst = list.filter(r => r.isChecked)
        setVote(lst, users.authUser.user_id, config.step)
        await lst.map(r => setVotesOptions(row => [...row, r.id]))
        setList(list)
    }

    /**
     * Handled to select year
     * @param {*} row
     */
    const handledToSelect = (row) => {
        const lst = []
        nominees.list.forEach((r, key) => {
            if (r.nominee_row === row) {
                lst.push({
                    id: r.nominated_id,
                    movie: r.movie,
                    actor: r.actor,
                    isChecked: votesOptions.includes(r.nominated_id) ? true : false,
                    disabled: false,
                    votes: parseInt(r.votes),
                    vote: votesOptions.includes(r.nominated_id) ? true : false,
                })
            }
        })
        lst.sort((a, b) => (parseInt(config.step) === 2) ? a.votes < b.votes : a.id > b.id)
        setList(lst)
        setModal({ ...modal, title: row, active: true })
    }

    /**
     * Handled option selection handler
     * @param {*} opt 
     */
    const handledSelectOpt = (opt) => {
        const lst = list.map((row) => ({
            ...row,
            isChecked: (row.id === opt.id) ? !row.isChecked : row.isChecked
        }))
        setList(lst)
    }

    return (
        <Page rows={rows} handledVote={handledVote} config={config} users={users} modal={modal} setModal={setModal}
            general={general} handledToSelect={handledToSelect} handledSelectOpt={handledSelectOpt} list={list} />
    );
}

const mapStateToProps = ({ users, nominees, general }) => ({
    users, nominees, general
});

const mapDispatchToProps = () => ({
    ...NomineesActions,
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Votes));