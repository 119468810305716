import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Page(props) {

    return (
        <>
            <Nav id="sidebar" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        {/*}<li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/dashboard">
                                <i className="fas fa-chart-line"></i>
                                Dashboard
                            </NavLink>
                        </li>{*/}
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/users">
                                <i className="fas fa-users-cog"></i>
                                Usuarios
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/members">
                                <i className="fas fa-users"></i>
                                Miembros
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/positions">
                                <i className="fas fa-sitemap"></i>
                                Posiciones
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/nominee-row">
                                <i className="fas fa-award"></i>
                                Renglón de nominado
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/movies">
                                <i className="fas fa-film"></i>
                                Películas
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/nominees">
                                <i className="fas fa-trophy"></i>
                                Nominados
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/vote-results">
                                <i className="fas fa-person-booth"></i>
                                Votaciones
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/petitions">
                                <i className="far fa-file-alt"></i>
                                Peticiones
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/vimeo">
                                <i className="fab fa-vimeo-v"></i>
                                Películas de Vimeo
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/vimeo-code">
                                <i className="fas fa-barcode"></i>
                                Código de Vimeo
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </Nav>
        </>
    );
}

export default Page;