import React, { useState } from "react";
import "./style.css";
import Header from '../../modules/panel/header'
import Sidebar from '../../modules/panel/sidebar'
import DataTable from 'react-data-table-component';
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import FileBase64 from 'react-file-base64';

function Page(props) {

  const { columns, paginationOptions, vimeo, show, setShow, form, setForm, handledAdd, errors, video, setVideo, handledUpdate } = props,
    [filterText, setFilterText] = useState(''),
    filteredItems = (vimeo.list ? vimeo.list : []).filter(item => {
      return (
        (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.created && item.created.includes(filterText))) ? true : false;
    });

  return (
    <>
      <Header></Header>
      <div className="container-fluid">
        <div className="row">
          <Sidebar></Sidebar>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 main-panel">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Vimeo</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2"></div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-sm-4 col-md-4 mb-3 mb-sm-0">
                    <div className="search-card input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                      <input type="search" className="form-control datatableSearch" placeholder="Buscar..." aria-label="Search orders" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8 mb-9 mb-sm-0" style={{ textAlign: "right" }}>
                    <Button variant="primary" onClick={() => setShow({ ...show, add: true })}><i className="fas fa-plus"></i>&nbsp;&nbsp;Crea Vimeo</Button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  noDataComponent={
                    <p style={{ color: "rgb(200, 200, 200)", fontStyle: "italic", paddingTop: 40, paddingBottom: 60 }}>
                      <img className="avatar-img" src={"/assets/no-data.png"} alt="" />
                      <span className="no-data">No hay registros para mostrar</span>
                    </p>
                  }
                  paginationComponentOptions={paginationOptions}
                />
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Add new vimeo */}
      <Modal show={show.add} onHide={() => setShow({ ...show, add: false })} className="modal-panel">
        <Modal.Header closeButton>
          <Modal.Title><i className="fas fa-plus"></i>&nbsp;&nbsp;Crea Vimeo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Título</Form.Label>
              <Form.Control type="text" style={{ borderColor: (errors.title) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                placeholder="Escriba el título..." value={form.title} onChange={(e) => setForm({ ...form, title: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Vimeo ID <i><small>(https://vimeo.com/<strong>789006133</strong>)</small></i></Form.Label>
              <Form.Control type="text" style={{ borderColor: (errors.vimeoId) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba el id de vimeo..."
                value={form.vimeoId} onChange={(e) => setForm({ ...form, vimeoId: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ display: "block", color: (errors.thumbnail) ? "rgb(200, 55, 55)" : "" }}>Miniatura</Form.Label>
              <span className="btn btn-primary btn-file">
                <i className="far fa-image pointer"></i>&nbsp;&nbsp;Buscar....
                <FileBase64 className="btn btn-primary" multiple={false} onDone={(file) => setForm({ ...form, thumbnail: file.base64 })} />
              </span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow({ ...show, add: false })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledAdd}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar</Button>
        </Modal.Footer>
      </Modal>

      {/* Update vimeo */}
      <Modal show={show.edit} onHide={() => setShow({ ...show, edit: false })} className="modal-panel">
        <Modal.Header closeButton>
          <Modal.Title><i className="fas fa-edit"></i>&nbsp;&nbsp;Editar Vimeo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control type="text" style={{ borderColor: (errors.title) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                placeholder="Escriba el título..." value={form.title} onChange={(e) => setForm({ ...form, title: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ display: "block", color: (errors.thumbnail) ? "rgb(200, 55, 55)" : "" }}>Miniatura</Form.Label>
              <span className="btn btn-primary btn-file">
                <i className="far fa-image pointer"></i>&nbsp;&nbsp;Buscar....
                <FileBase64 className="btn btn-primary" multiple={false} onDone={(file) => console.log(file.base64) /* setForm({ ...form, thumbnail: file.base64 })*/} />
              </span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow({ ...show, edit: false })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledUpdate}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar</Button>
        </Modal.Footer>
      </Modal>

      {/* View vimeo video */}
      <Modal show={video.view} size="lg" onHide={() => setVideo({ view: false, code: "" })} className="modal-panel">
        <Modal.Header closeButton>
          <Modal.Title><i className="fas fa-edit"></i>&nbsp;&nbsp;Ver video de Vimeo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            video.code &&
            <>
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe src={`https://player.vimeo.com/video/${video.code}?h=&title=0&byline=0&portrait=0`}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setVideo({ view: false, code: "" })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledUpdate}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Page;