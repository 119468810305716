import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../modules/page/footer";
import Header from "../../modules/page/header";
import "./style.css";

function Page(props) {

  return (
    <>
      <Header></Header>

      <section id="m-top">
        <Container>
          <h2 className="title-page">¿Por qué ser miembro de ADOCINE?</h2>
          <p className="decription"><strong>ADOCINE</strong> es el lugar donde convergen los más prestigiosos profesionales de la industria cinematográfica de la Republica Dominicana, además de poser como beneficios:</p>
        </Container>
        <Container id="m-why-member">
          <Row>
            <Col>
              <Image src={"../assets/ribbons.webp"} />
            </Col>
            <Col>
              <ul>
                <li><span>1</span><p>Representación En el CIPAC.</p></li>
                <li><span>2</span><p>Asistencia Gratuita a los conversatorios, Talleres y múltiples.</p></li>
                <li><span>3</span><p>Actividades organizadas por adocine.</p></li>
                <li><span>4</span><p>Derecho a voto en los premios La Silla.</p></li>
                <li><span>5</span><p>Participación y derecho a voto en las asambleas bi-anual de la asociación.</p></li>
                <li><span>6</span><p>Derecho a optar por un cargo en la directiva.</p></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="m-member">
        <Container>
          <h2 className="title-page">¿Cómo ser miembro de ADOCINE?</h2>
          <p className="decription">Para registratre como miembro de ADOCINE:</p>
          <Row>
            <Col>
              <ul>
                <li>1. Completa el formulario de registro.</li>
                <li>2. La membresía anual a ADOCINE tiene un <strong>costo de RD$1,200.00</strong>, a ser depositados en:</li>
                <li>Cuenta corriente ADOCINE<br />Banco de Reservas #240-0169009</li>
                <li>3. Una vez realizado el depósito deben enviar el comprobante a info@adocine.org junto con el formulario lleno.</li>
              </ul>
              <Link to="#" className="btn btn-adocine">&nbsp;&nbsp;Formulario de inscripción&nbsp;&nbsp;</Link>
            </Col>
            <Col>
              <Image src={"../assets/stage2.webp"} />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="m-member-requirements">
        <Container>
          <Row>
            <Col>
              <Image src={"../assets/old-camera.webp"} />
            </Col>
            <Col>
              <h2 className="title-page">Para ser miembro de
                ADOCINE, se debe cumplir
                con uno o varios de los
                siguientes requisitos:</h2>
              <ul>
                <li><span></span><p>Haber realizado o comercializado producciones cinematográficas dominicanas.</p></li>
                <li><span></span><p>Haber realizado estudios de producción cinematográficaa nivel especializado, con experiencia profesional comprobada.</p></li>
                <li><span></span><p>Haber realizado inversiones significativas en producciones cinematográficas nacionales.</p></li>
                <li><span></span><p>Dedicarse a la renta o distribución de equipos y materiales para la producción cinematográfica en el país.</p></li>
                <li><span></span><p>Ser un profesional activo en la industria nacional del cine.</p></li>
                <li><span></span><p>Participar en la industria cinematográfica nacional en su aporte y fomento.</p></li>
                <li><span></span><p>Cualquier otra nacional dominicano que por su contribución a la realización cinematográfica local, sea merecedor de la distinción.</p></li>
                <li><span></span><p>Toda persona física o moral que desee ser miembro de la entidad, debe estar recomendada por al menos tres (3) Miembros Activos y su ingreso debe ser aprobado por la Junta de Directores y ratificado en Asamblea Ordinaria.</p></li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="title-page">Miembros Activos</h2>
              <p>para cualquier cargo de la entidad, por lo que tendrán voz y voto en las asambleas, el cual deberá ser ejercido de manera directa o por representación, en cuyo caso deberá el representante proveerse de un poder escrito con las firmas legalizadas por un Notario Público tanto del representante como del representado. Los Miembros Activos, para tener el estatus de tales, deben están al día con la cuotas de membresía y asistir de manera física o representados por terceros, a las Asambleas Ordinarias o Extraordinarias que requieran de votación.</p>
              <h2 className="title-page">Miembros Pasivos</h2>
              <p>Son aquellos que han perdido provisionalmente su condición de Miembros Activos, pudiendo readquirir dicha condición tan pronto cese la causa que lo motiva. Son Miembros Pasivos a) los que se encuentren en defecto de pago de cuotas; b) hayan sido sancionados por alguna falta descrita en el Capítulo II de los Estatutos; c) no asistan a las Asambleas. Aquellos Miembros Pasivos con defecto de cuotas o con sanciones, podrán asistir a las Asambleas, pero no tendrán derecho a elegir ni a ser elegidos, ni tendrán voto en las mismas.</p>
            </Col>
            <Col>
              <Image src={"../assets/people.webp"} />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="m-member-footer">
        <Container>
          <h2 className="title-page">Miembros Honoríficos</h2>
          <p>Son aquellos que en función de sus aportaciones a la entidad y de su notable contribución a los fines y objetos perseguidos por esta Asociación, sean dignos de esta condición a juicio del Consejo de Directores y sean ratificados por asamblea.</p>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
}

export default Page;
