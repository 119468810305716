import React from 'react'
import Page from './page'
import { connect } from 'react-redux'
import UserActions from '../../../redux/actions/Users'
import { withRouter, useLocation } from 'react-router-dom'

const Header = ({ users, general, setSignOff, history }) => {

    const location = useLocation()

    const
        /**
         * Handled sign off
         */
        handledSignOff = () => {
            setSignOff(history, "page");
        }

    return (<Page users={users} handledSignOff={handledSignOff} general={general} location={location} />);
}

const mapStateToProps = ({ users, general }) => ({
    general,
    users
});

const mapDispatchToProps = () => ({
    ...UserActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Header));