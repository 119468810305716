import React from "react";
import Footer from "../../modules/page/footer";
import Header from "../../modules/page/header";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

function Page(props) {

  return (
    <>
      <Header></Header>
      <section id="hi-health-insurance">
        <Container>
          <h2 className="title-page">ADOCINE & CORE: SEGURO DE SALUD ARS FUTURO.</h2>
          <Row>
            <Col>
              <h3>Un nuevo beneficio para los miembros de Adocine, de un Seguro de Salud privado para ellos y sus familiares.</h3>
            </Col>
            <Col>
              <div className="card-list">
                <ul>
                  <li><span>1</span><p>Llena el formulario de nuestra página.</p></li>
                  <li><span>2</span><p>Visita la oficina de ARS Futuro, con tu cédula en mano.</p></li>
                  <li><span>3</span><p>Te ofrecemos un Seguro de Salud con tarifas especiales para miembros.</p></li>
                </ul>
                <ul>
                  <li><span>4</span><p>Cobertura de de más de un 80%.</p></li>
                  <li><span>5</span><p>Atención inmediata para emergencias.</p></li>
                  <li><span>6</span><p>Facilidades para la emisión del seguro.</p></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer transparent="true"></Footer>
      </section>
    </>
  );
}

export default Page;
