import React, { useEffect } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import MoviesActions from '../../redux/actions/Movies';
import { useParams, withRouter } from 'react-router-dom';

const Movies = ({ movies, getMovie }) => {

    const { mid } = useParams();

    useEffect(() => {
        getMovie(mid)
    }, []);

    return (
        <Page movies={movies} />
    );
}

const mapStateToProps = ({ movies }) => ({
    movies
});

const mapDispatchToProps = () => ({
    ...MoviesActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Movies));