import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Page(props) {

    const { general, handledSignOff } = props

    return (
        <>
            <div id="loading" style={{ display: (general.loading) ? "flex" : "none" }}>
                <div className="background"></div>
                <div className="container">
                    <i className="fa-3x fas fa-spinner fa-spin"></i>
                    <label className="text">Espere...</label>
                </div>
            </div>
            <header id="header-panel" className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <Link to="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3"><Image src={"../assets/logo.png"} /></Link>
                <button className="navbar-toggler position-absolute d-md-none collapsed" type="button">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <span onClick={handledSignOff} className="sign-off">
                    <i className="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Cerrar Sesión
                </span>
            </header>
        </>
    );
}

export default Page;