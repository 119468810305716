import React, { useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import GeneralActions from '../../../redux/actions/General';
import { withRouter } from 'react-router-dom';

const Contact = ({ setSendContact }) => {

    const [form, setForm] = useState({ bg: "" }),
        [errors, setErrors] = useState({})

    const handleSendForm = () => {
        if (handleValidate()) {
            setSendContact(form)
            setForm({ ...form, send: true })
        }
    };
    /**
    * Validate the form
    * @returns true or false
    */
    const handleValidate = () => {
        setErrors({})
        if (!form.email || !form.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setErrors({ email: true });
            return false;
        } else if (!form.subject) {
            setErrors({ subject: true });
            return false;
        } else if (!form.comment) {
            setErrors({ comment: true });
            return false;
        }
        return true;
    };

    return (
        <Page form={form} setForm={setForm} errors={errors} handleSendForm={handleSendForm} />
    );
}
const mapStateToProps = ({ general }) => ({
    general
});

const mapDispatchToProps = () => ({
    ...GeneralActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Contact));