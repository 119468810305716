import React from 'react';
import "./style.css";
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Page(props) {

    const { users, handledSignOff, general, location } = props

    return (
        <>
            <div id="loading" style={{ display: (general.loading) ? "flex" : "none" }}>
                <div className="background"></div>
                <div className="container">
                    <i className="fa-3x fas fa-spinner fa-spin"></i>
                    <label className="text">Espere...</label>
                </div>
            </div>
            <header id='header-page'>
                <Container>
                    <Row>
                        <Col xs={12} md={4}>
                            <Link to="/"><Image src={"../assets/logo.png"} /></Link>
                        </Col>
                        <Col xs={12} md={4} style={{ textAlign: "center" }}>
                            <ul id="social-media-header">
                                <li><Link to={{ pathname: "https://www.facebook.com/adocinerd/" }} target="_blank"><i className="fab fa-facebook-f" /></Link></li>
                                <li><Link to={{ pathname: "https://www.instagram.com/Adocine/" }} target="_blank"><i className="fab fa-instagram" /></Link></li>
                                <li><Link to={{ pathname: "https://mobile.twitter.com/ADOCINE" }} target="_blank"><i className="fab fa-twitter" /></Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="options">
                                {users.authUser &&
                                    <>
                                        <Link to="/profile" style={{ display: (users.authUser.user_type !== "admin") ? "inline-block" : "none" }} className='option-auth'>
                                            <i className="fas fa-user"></i>&nbsp;&nbsp;Mi Perfil
                                        </Link>
                                        <Link to="/users" style={{ display: (users.authUser.user_type === "admin") ? "inline-block" : "none" }} className='option-auth'>
                                            Ir al Panel
                                        </Link>
                                        <span className='bar-auth'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                        <span className='option-auth' onClick={handledSignOff}><i className="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Cerrar Sesión</span>
                                    </>
                                } {(!users.authUser && !['/login', '/forget-password'].includes(location.pathname)) && <Link className='button' to="login">Iniciar Sesión</Link>}
                                <span className='nav'>
                                    <i className="fas fa-bars"></i>
                                    <div className='menu'>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/nominations">Nominaciones</Link></li>
                                            {
                                                users.authUser && <>
                                                    <li><Link to="/player">Películas</Link></li>
                                                    <li><Link to="/votes">Votaciones</Link></li>
                                                </>
                                            }
                                            {/*}
                                            <li><Link to="/about">Adocine</Link></li>
                                            <li><Link to="/membership">Membresía</Link></li>
                                            <li><Link to="/health-insurance">Seguro de Salud</Link></li>{*/}
                                            <li><Link to="/contact">Contacto</Link></li>
                                        </ul>
                                    </div>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    );
}

export default Page;