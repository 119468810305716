import { confirmAlert } from 'react-confirm-alert';
import { vimeo as vimeoTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

/**
 * Get list of positions
 * @returns 
 */
const getVimeos = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListVimeos(dispatch)
};

/**
 * Add new position
 * @param {*} form 
 * @returns 
 */
const setVimeo = (form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true })
    try {
        //const resp = await axios.get(`https://vimeo.com/api/v2/video/${form.vimeoId}.json`)
        //const { id, title, thumbnail_large, width, height } = resp.data[0]
        await axios.post(process.env.REACT_APP_URL+"vimeo", { code: form.vimeoId, title: form.title, thumbnail: form.thumbnail })
        getListVimeos(dispatch)
    } catch (error) {
        /*if(error?.response?.status === 404) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;No encontrado</h3>
                            <p>El ID del vídeo digitado no existe.</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose}><i className="fas fa-times"></i>&nbsp;Cerrar</button>
                            </div>
                        </div>
                    )
                }
            });
        }*/
    } finally {
        dispatch({ type: generalTypes.LOADING, payload: false })
    }
};

/**
 * Get position by pid
 * @param {*} pid 
 */
const getVimeo = (pid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'vimeo/' + pid).then(resp => {
        dispatch({ type: vimeoTypes.GET_VIMEO, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Update position by pid
 * @param {*} form 
 * @returns 
 */
const setUpdate = (pid, form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'vimeo/' + pid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListVimeos(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove position by pid
 * @param {*} pid 
 * @param {*} onClose 
 * @returns 
 */
const setRemove = (pid, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'vimeo/' + pid).then(resp => {
        getListVimeos(dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Clear vimeo
 * @returns 
 */
const setClearVimeo = () => async (dispatch) => {
    dispatch({ type: vimeoTypes.GET_VIMEO, payload: null });
};

/**
 * Export const
 */
const exportConst = {
    setClearVimeo,
    getVimeos,
    getVimeo,
    setVimeo,
    setRemove,
    setUpdate
};

/**
 * Get list of vimeos
 * @param {*} dispatch 
 * @param {*} callBack 
 */
async function getListVimeos(dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'vimeos').then(resp => {
        dispatch({ type: vimeoTypes.GET_VIMEOS, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;