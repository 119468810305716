import { nominees as nomineesTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';

/**
 * Get list of nominees
 * @returns 
 */
const getNominees = (uid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListNominees(dispatch)
};

/**
 * Get list of nominees
 * @returns 
 */
 const getNomineesByUid = (uid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'nominees-by-user/'+uid).then(resp => {
        dispatch({ type: nomineesTypes.GET_NOMINEES, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Add new nominee
 * @param {*} form 
 * @returns 
 */
const setNominee = (form) => async (dispatch) => {
    await axios.post(process.env.REACT_APP_URL + 'nominee', form).then(resp => {
        getListNominees(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Get nominee by nid
 * @param {*} nid 
 */
const getNominee = (nid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'nominee/' + nid).then(resp => {
        dispatch({ type: nomineesTypes.GET_NOMINEE, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Update nominee by nid
 * @param {*} form 
 * @returns 
 */
const setUpdate = (nid, form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'nominee/' + nid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListNominees(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove nominee by nid
 * @param {*} nid 
 * @param {*} onClose 
 * @returns 
 */
const setRemove = (nid, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'nominee/' + nid).then(resp => {
        getListNominees(dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Clear nominee
 * @returns 
 */
const setClearNominee = () => async (dispatch) => {
    dispatch({ type: nomineesTypes.GET_NOMINEE, payload: null });
};

/**
 * Export const
 */
const exportConst = {
    getNomineesByUid,
    setClearNominee,
    getNominees,
    getNominee,
    setNominee,
    setRemove,
    setUpdate
};

/**
 * Get list of nominees
 * @param {*} dispatch 
 * @param {*} callBack 
 */
async function getListNominees(dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'nominees').then(resp => {
        dispatch({ type: nomineesTypes.GET_NOMINEES, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;