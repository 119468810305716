import { nomineeRow as nomineeRowTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';

/**
 * Get list of nominee rows
 * @returns 
 */
const getNomineeRows = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListNomineeRows(dispatch)
};

/**
 * Add new nominee row
 * @param {*} form 
 * @returns 
 */
const setNomineeRow = (form) => async (dispatch) => {
    await axios.post(process.env.REACT_APP_URL + 'nomineerow', form).then(resp => {
        getListNomineeRows(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Get nominee row by nid
 * @param {*} pid 
 */
const getNomineeRow = (nid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'nomineerow/' + nid).then(resp => {
        dispatch({ type: nomineeRowTypes.GET_NOMINEE_ROW, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Update nominee row by nid
 * @param {*} form 
 * @returns 
 */
const setUpdate = (nid, form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'nomineerow/' + nid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListNomineeRows(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove nominee row by nid
 * @param {*} nid 
 * @param {*} onClose 
 * @returns 
 */
const setRemove = (nid, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'nomineerow/' + nid).then(resp => {
        getListNomineeRows(dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Clear nominee row
 * @returns 
 */
 const setClearNomineeRow = () => async (dispatch) => { 
    dispatch({ type: nomineeRowTypes.GET_NOMINEE_ROW, payload: null });
};

/**
 * Export const
 */
const exportConst = {
    setClearNomineeRow,
    getNomineeRows,
    getNomineeRow,
    setNomineeRow,
    setRemove,
    setUpdate
};

/**
 * Get list of nominee rows
 * @param {*} dispatch 
 * @param {*} callBack
 */
async function getListNomineeRows(dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'nomineerows').then(resp => {
        dispatch({ type: nomineeRowTypes.GET_NOMINEE_ROWS, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;