import { users as userTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { infoAlert } from '../../library/utilities';

/**
 * Verify user authentication
 * @returns 
 */
const setAuthentication = (form, history) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.post(process.env.REACT_APP_URL + 'authentication', {
        username: form.username,
        password: form.password
    }).then(resp => {
        dispatch({ type: userTypes.AUTH, payload: resp.data });
        dispatch({ type: userTypes.GET_USER, payload: null });
        if (resp.data.user_type === "admin") {
            history.push("/users")
        } else {
            history.push("/votes")
        }
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui-page'>
                        <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Error al autenticar</h3>
                        <p>{err.response.data.messages.error}</p>
                        <div className="text-center">
                            <button className="btn btn-adocine" onClick={onClose}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</button>
                        </div>
                    </div>
                )
            }
        });
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Log out user
 * @param {*} history 
 * @returns 
 */
const setSignOff = (history, css = "panel") => (dispatch) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                (css === "page") ?
                    <div className='custom-ui-page'>
                        <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Cerrar Sesión</h3>
                        <p>¿Realmente quieres cerrar la sesión?</p>
                        <div className="text-center">
                            <button className="btn btn-adocine" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancelar</button>
                            <button className="btn btn-adocine" onClick={() => { dispatch({ type: userTypes.AUTH, payload: null }); history.push("/"); onClose() }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                        </div>
                    </div>
                    :
                    <div className={'custom-ui-' + css}>
                        <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Cerrar Sesión</h3>
                        <p>¿Realmente quieres cerrar la sesión?</p>
                        <div className="text-center">
                            <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancelar</button>
                            <button className="btn btn-success" onClick={() => { dispatch({ type: userTypes.AUTH, payload: null }); history.push("/"); onClose() }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                        </div>
                    </div>
            )
        }
    });
}

/**
 * Change password by user id
 * @param {*} uid 
 * @param {*} form 
 * @returns 
 */
const setChangePassword = (uid, form, callBack) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'password/' + uid, {
        password: form.password,
        new_password: form.new_password
    }).then(resp => {
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui-page'>
                        <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Error</h3>
                        <p>{err.response.data.messages.error}</p>
                        <div className="text-center">
                            <button className="btn btn-adocine" onClick={onClose}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</button>
                        </div>
                    </div>
                )
            }
        });
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Change password by user id
 * @param {*} uid 
 * @param {*} form 
 * @returns 
 */
const setResetPassword = (email, callBack) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'forget-password/' + email).then(resp => {
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui-page'>
                        <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Error</h3>
                        <p>{err.response.data.messages.error}</p>
                        <div className="text-center">
                            <button className="btn btn-adocine" onClick={onClose}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</button>
                        </div>
                    </div>
                )
            }
        });
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
};

/**
 * Get list of users
 * @returns 
 */
const getUsers = (type) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListUsers(type, dispatch)
};

/**
 * Get user by uid
 * @param {*} uid 
 */
const getUser = (uid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'user/' + uid).then(resp => {
        dispatch({ type: userTypes.GET_USER, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Get member by uid
 * @param {*} uid 
 * @returns 
 */
const getMember = (uid) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getMemberByUID(uid, dispatch)
}

/**
 * Add new user
 * @param {*} form 
 * @returns 
 */
const setUser = (form, type) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.post(process.env.REACT_APP_URL + 'user', form).then(resp => {
        getListUsers(type, dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Update user by uid
 * @param {*} form 
 * @returns 
 */
const setUpdateUser = (uid, form, type) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'user/' + uid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListUsers(type, dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Update member by uid
 * @param {*} form 
 * @returns 
 */
const setUpdateMember = (uid, form, type = null) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'member/' + uid, form).then(resp => {
        if (type) {
            getListUsers(type, dispatch)
        } else {
            getMemberByUID(uid, dispatch)
        }
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove user by uid
 * @param {*} uid 
 * @param {*} onClose 
 * @returns 
 */
const setRemoveUser = (uid, type, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'user/' + uid).then(resp => {
        getListUsers(type, dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * 
 * @param {*} callBack 
 * @returns 
 */
const generateCode = (callBack) => async (dispatch, getState) => {
    try {
        dispatch({ type: generalTypes.LOADING, payload: true })
        const { users } = getState()
        const resp = await axios.post(`${process.env.REACT_APP_URL}generate-code`, { uid: users.authUser.user_id })
        callBack(resp.data)
        dispatch({ type: userTypes.AUTH, payload: { ...users.authUser, vimeo_code: resp.data.code } });
    } catch (error) {
        infoAlert("Error al genera código", error?.response?.data?.messages?.error)
    } finally {
        dispatch({ type: generalTypes.LOADING, payload: false });
    }
}

/**
 * Clear user
 * @returns 
 */
const setClearUser = () => (dispatch) => {
    dispatch({ type: userTypes.GET_USER, payload: null });
}

/**
 * Export const
 */
const exportConst = {
    generateCode,
    setChangePassword,
    setAuthentication,
    setResetPassword,
    setUpdateMember,
    setUpdateUser,
    setRemoveUser,
    setClearUser,
    setSignOff,
    getMember,
    getUsers,
    setUser,
    getUser
};

/**
 * Get list of user by type
 * @param {*} dispatch 
 * @param {*} callBack 
 */
async function getListUsers(type, dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'users/' + type).then(resp => {
        dispatch({ type: userTypes.GET_USERS, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

/**
 * Get member by user id
 * @param {*} uid 
 * @param {*} dispatch 
 */
async function getMemberByUID(uid, dispatch) {
    await axios.get(process.env.REACT_APP_URL + 'member/' + uid).then(resp => {
        dispatch({ type: userTypes.GET_USER, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;