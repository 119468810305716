import React, { useEffect, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import VimeosActions from '../../redux/actions/Vimeo';
import VimeoCodesActions from '../../redux/actions/VimeoCode';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const VimeoCode = ({ vimeo, vimeoCode, getVimeos, getVimeosCodes, setVimeoCode, setRemove, setClearVimeoCode }) => {

    const [show, setShow] = useState({ add: false, edit: false }),
        [form, setForm] = useState({ vimeoId: [] }),
        [errors, setErrors] = useState({}),
        [lstvimeo, setLstVimeo] = useState({})

    useEffect(() => {
        getVimeos()
        getVimeosCodes()
    }, [getVimeosCodes, getVimeos]);

    useEffect(() => {
        if (vimeo.list) {
            const lst = vimeo.list.map(row => ({ value: row.vimeo_id, label: row.title }))
            setLstVimeo(lst)
        }
    }, [vimeo.list]);

    useEffect(() => {
        if (vimeo.selected) {
            setForm({
                title: vimeo.selected.title
            })
            setShow({ ...show, edit: true })
        }
    }, [vimeo.selected]);

    useEffect(() => {
        if (!show.add && !show.edit) {
            setClearVimeoCode()
            setForm({ name: "", active: 1 })
        }
    }, [show.edit, show.add]);

    const

        /**
         * Handled add new position
         */
        handledAdd = () => {
            if (handleValidate()) {
                setVimeoCode({ vimeo_id: form.vimeoId.value })
                setShow({ ...show, add: false })
            }
        },

        /**
         * Handled remove position
         */
        handledRemove = (pid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Vimeo</h3>
                            <p>¿Realmente deseas eliminar este vimeo?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemove(pid, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            });
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.vimeoId?.value) {
                setErrors({ vimeoId: true });
                return false;
            }
            return true;
        };

    const columns = [
        {
            name: 'Code', selector: row => row.code, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.code}</span>
            )
        }, {
            name: 'Título', selector: row => row.title, minWidth: '40%', maxWidth: '40%', sortable: true, cell: row => (
                <span>{row.title}</span>
            )
        }, {
            name: 'Mienbro', selector: row => row.full_name, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{(row.full_name) ? row.full_name : "-"}</span>
            )
        }, {
            name: 'Vistas', selector: row => row.full_name, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.view ? (row.view > 3) ? "Expirado" : row.view : "-"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY hh:mm A")}</span>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} vimeoCode={vimeoCode} show={show} setShow={setShow} form={form} errors={errors} lstvimeo={lstvimeo}
            setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} />
    );
}

const mapStateToProps = ({ vimeo, vimeoCode }) => ({
    vimeo,
    vimeoCode
});

const mapDispatchToProps = () => ({
    ...VimeosActions,
    ...VimeoCodesActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(VimeoCode));