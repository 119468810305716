import { vimeoCode as vimeoCodeTypes } from '../constants/ActionTypes';
import { general as generalTypes } from '../constants/ActionTypes';
import axios from 'axios';
import { infoAlert } from '../../library/utilities';

/**
 * Get list of video code
 * @returns 
 */
const getVimeosCodes = () => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    getListVimeosCodes(dispatch)
};

/**
 * Add new vimeo code
 * @param {*} form 
 * @returns 
 */
const setVimeoCode = (form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true })
    try {
        await axios.post(process.env.REACT_APP_URL + "vimeo-code", form)
        getListVimeosCodes(dispatch)
    } catch (error) {
        console.log(error)
    } finally {
        dispatch({ type: generalTypes.LOADING, payload: false })
    }
};

/**
 * Get vimeo code by pid
 * @param {*} pid 
 */
const getVimeoCode = (code, video, callBack) => async (dispatch, getState) => {
    const { users } = getState()
    try {
        if (users.authUser.vimeo_code === code) {
            const resp = await axios.get(`${process.env.REACT_APP_URL}vimeo-code/${code}/${video.vimeo_id}/${users.authUser.user_id}`)
            const { vimeo_code_id, view } = resp.data
            if (view >= 3) {
                infoAlert("Límite excedido", "Solo puedo ver la misma película un máximo de 3 veces.")
            } else {
                await axios.put(process.env.REACT_APP_URL + 'vimeo-code/' + vimeo_code_id, {
                    view: parseInt(view) + 1
                })
                callBack()
            }
        } else {
            infoAlert("Código erroneo", "El código digitado no está relacionado a esta cuenta.")
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            await axios.post(`${process.env.REACT_APP_URL}vimeo-code`, {
                code: code,
                vimeo_id: video.vimeo_id,
                user_id: users.authUser.user_id
            })
            callBack()
        }
    } finally {
        dispatch({ type: generalTypes.LOADING, payload: false });
    }
}

/**
 * Update video code by pid
 * @param {*} form 
 * @returns 
 */
const setUpdate = (pid, form) => async (dispatch) => {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.put(process.env.REACT_APP_URL + 'vimeo-code/' + pid, { ...form, active: (form.active) ? 1 : 0 }).then(resp => {
        getListVimeosCodes(dispatch)
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Remove video code by pid
 * @param {*} pid 
 * @param {*} onClose 
 * @returns 
 */
const setRemove = (pid, onClose) => async (dispatch) => {
    await axios.delete(process.env.REACT_APP_URL + 'vimeo-code/' + pid).then(resp => {
        getListVimeosCodes(dispatch, () => onClose())
    }).catch(err => { dispatch({ type: generalTypes.LOADING, payload: false }); });
};

/**
 * Reset vimeo code
 * @param {*} code 
 * @returns 
 */
const setResetVimeoCode = (code, onClose) => async (dispatch) => {
    try {
        await axios.delete(process.env.REACT_APP_URL + 'reset-vimeo-code/' + code)
        onClose()
    } catch (error) {
    } finally {
        dispatch({ type: generalTypes.LOADING, payload: false })
    }
}

/**
 * Clear vimeo
 * @returns 
 */
const setClearVimeoCode = () => async (dispatch) => {
    dispatch({ type: vimeoCodeTypes.GET_VIMEOCODE, payload: null });
};

/**
 * Export const
 */
const exportConst = {
    setResetVimeoCode,
    setClearVimeoCode,
    getVimeosCodes,
    getVimeoCode,
    setVimeoCode,
    setRemove,
    setUpdate
};

/**
 * Get list of vimeos
 * @param {*} dispatch 
 * @param {*} callBack 
 */
async function getListVimeosCodes(dispatch, callBack = function () { }) {
    dispatch({ type: generalTypes.LOADING, payload: true });
    await axios.get(process.env.REACT_APP_URL + 'vimeo-codes').then(resp => {
        dispatch({ type: vimeoCodeTypes.GET_VIMEOSCODES, payload: resp.data });
        dispatch({ type: generalTypes.LOADING, payload: false });
        callBack()
    }).catch(err => {
        dispatch({ type: generalTypes.LOADING, payload: false });
    });
}

export default exportConst;