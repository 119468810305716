import React from "react"
import "./style.css"
import Header from "../../modules/page/header"
import Footer from "../../modules/page/footer"
import { Button, Form } from "react-bootstrap"
import { Link } from "react-router-dom"

function Page(props) {

  const { form, setForm, errors, handledForgetPassword, send } = props

  return (
    <>
      <Header></Header>
      <section id="forget-password">
        <div className="div-forget-password">
          <Form>
            <h5>¿Olvidaste tu contraseña?</h5>
            {
              send &&
              <div style={{ textAlign: "center", marginTop: 30 }}>
                <i className="far fa-paper-plane" style={{ fontSize: 50, marginBottom: 20, color: "#575757" }}></i>
                <p style={{ display: "block" }}>
                  Hemos enviado un correo electrónico con una nueva contraseña, revise su bandeja de entrada de correo electrónico.
                </p>
              </div>
            } {
              !send &&
              <>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Escriba su correo electrónico..." style={{ borderColor: (errors.email) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, email: e.target.value })} />
                </Form.Group>
                <Button variant="primary" onClick={handledForgetPassword}>
                  <i className="far fa-paper-plane"></i>&nbsp;&nbsp;Enviar contraseña
                </Button>
              </>
            }
            <div>
              <Link className="forget-password" to="/login">
                <i className="fas fa-long-arrow-alt-left"></i>&nbsp;&nbsp;Volver atras
              </Link>
            </div>
          </Form>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Page;