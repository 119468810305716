import React from "react";
import "./style.css";
import Header from "../../modules/page/header";
import Footer from "../../modules/page/footer";
import NumberFormat from 'react-number-format'
import { Container, Row, Col, Image, Modal, Button, Form } from "react-bootstrap";
import Contact from "../../modules/page/contact";
import BoardDirectors from "../../modules/page/board-directors";
import Objectives from "../../modules/page/objectives";
import { Link } from "react-router-dom";

function Page(props) {

  const { movies, setShow, show, setForm, form, errors, handledAdd, done } = props

  return (
    <>
      <Header></Header>
      {/*}
        <section id="h-top">
          <div>
            <h1>ADOCINE</h1>
            <p>Es la asociación de mayor <span>alcance y prestigio</span> en todas las esferas del cine dominicano</p>
            <h3>¿Quiéres formar parte de nosotros?</h3>

            <p style={{ marginBottom: "70px" }}>Autorizo a ADOCINE a incluir mi película entre las prenominadas a los Premios la Silla {new Date().getFullYear()}.</p>
            <a className="btn btn-adocine button" target={"_blank"} href="https://share.hsforms.com/1g3PvMEQESHiFWGmxBAh28Qe4uyl">Formulario de inscripción</a>
            {
              /*(users.authUser) ?
                <span className="button" onClick={() => setShow(true)}>Incluir Película</span>
                : ""*
            }
          </div>
        </section>
        <section id="h-why-member">
          <Container>
            <h2 className="title-page">¿Por qué ser miembro de ADOCINE?</h2>
            <p className="decription"><strong>ADOCINE</strong> es el lugar donde convergen los más prestigiosos profesionales de la industria cinematográfica de la Republica Dominicana, además de poser como beneficios:</p>
            <Row>
              <Col xs={12} md={6}>
                <Image src={"../assets/cameraman.png"} style={{ width: "100%" }} />
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li><span>1</span><p>Representación En el CIPAC.</p></li>
                  <li><span>2</span><p>Asistencia Gratuita a los conversatorios, Talleres y múltiples.</p></li>
                  <li><span>3</span><p>Actividades organizadas por adocine.</p></li>
                  <li><span>4</span><p>Derecho a voto en los premios La Silla.</p></li>
                  <li><span>5</span><p>Participación y derecho a voto en las asambleas bi-anual de la asociación.</p></li>
                  <li><span>6</span><p>Derecho a optar por un cargo en la directiva.</p></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      {*/}
      <section id="h-vote">
        <Container>
          <h2 className="title-page">Votación</h2>
          <h4>Nominaciones LA SILLA {new Date().getFullYear()}</h4>
          <p>Nominaciones oficiales de la industria cinematográfica</p>
          <div className="movies">
            {
              movies.list.map((row, key) => {
                if (key <= 5) {
                  return (
                    <div className="movie" key={key}>
                      <div className="cover">
                        <div className="text">
                          <h3>{row.name}</h3>
                          <p>Género: {row.name}.</p>
                          <p>Director: {row.director}.</p>
                          <p>Productor: {row.producer}</p>
                          <p>Guión: {row.script}</p>
                          <p>Género: {row.gender}</p>
                          <Link className="button" to={"/movie/" + row.movie_id}>Ver más</Link>
                        </div>
                      </div>
                      <Image src={(row.cover) ? row.cover : "../../assets/cover.webp"} />
                    </div>
                  )
                }
              })
            }
          </div>
          <Link className="button" to="/nominations">Ver más</Link>
        </Container>
      </section>
      {/*}
      <Objectives></Objectives>

      <section id="h-ars">
        <Container>
          <h2 className="title-page" style={{ marginBottom: "0px" }}>ADOCINE & CORE:</h2>
          <h2 className="title-page" style={{ marginBottom: "0px" }}>SEGURO DE SALUD ARS</h2>
          <h2 className="title-page" style={{ marginBottom: "40px" }}>FUTURO</h2>
          <p>Un nuevo beneficio para los miembros de Adocine, de un Seguro de</p>
          <p>Salud privado para ellos y sus familiares.</p>
          <p>Te enviaremos información siempre que hayan novedades</p>
          <p>directamente a tu correo electrónico.</p>
          <h3>Pasos a seguir:</h3>
          <Row>
            <Col xs={6} md={4} style={{ textAlign: "center" }}>
              <Image src={"../assets/icons/f.png"} />
              <p>Llena el formulario de nuestra página.</p>
            </Col>
            <Col xs={6} md={4} style={{ textAlign: "center" }}>
              <Image src={"../assets/icons/e.png"} />
              <p>Visita la oficina de ARS Futuro, con tu cédula en mano.</p>
            </Col>
            <Col xs={6} md={4} style={{ textAlign: "center" }}>
              <Image src={"../assets/icons/d.png"} />
              <p>Te ofrecemos un Seguro de Salud con tarifas especiales para miembros.</p>
            </Col>
            <Col xs={6} md={4} style={{ textAlign: "center" }}>
              <Image src={"../assets/icons/c.png"} />
              <p>Cobertura de de más de un 80%.</p>
            </Col>
            <Col xs={6} md={4} style={{ textAlign: "center" }}>
              <Image src={"../assets/icons/b.png"} />
              <p>Atención inmediata para emergencias.</p>
            </Col>
            <Col xs={6} md={4} style={{ textAlign: "center" }}>
              <Image src={"../assets/icons/a.png"} />
              <p>Facilidades para la emisión del seguro.</p>
            </Col>
          </Row>
          <a className="btn btn-adocine button" target="_blank" href="https://share.hsforms.com/1g3PvMEQESHiFWGmxBAh28Qe4uyl">Formulario de inscripción</a>
        </Container>
          </section>{*/}
      <BoardDirectors></BoardDirectors>
      <Contact nav={false}></Contact>
      <Footer></Footer>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Incluir Película</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ display: (done) ? "none" : "block" }}>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Nombre de la película</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.name) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Género</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.gender) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, gender: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Productor(es)</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.producer) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, producer: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Director/a</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.director) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, director: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Guión</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.script) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, script: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Dirección cinematográfica</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.cinematographic_direction) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, cinematographic_direction: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Edición</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.edition) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, edition: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Dirección de arte</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.art_direction) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, art_direction: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Diseño de producción</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.production_design) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, production_design: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Diseñador de sonido</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.sound_design) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, sound_design: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Musicalización</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.musicalization) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, musicalization: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Canción (Tema Principal)</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.song) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, song: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Efectos especiales</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.special_effects) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, special_effects: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Efectos visuales</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.visual_effects) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, visual_effects: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Maquillaje</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.make_up) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, make_up: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vestuario</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.locker_room) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, locker_room: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Actriz principal</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.principal_actress) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, principal_actress: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Actriz secundaria</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.supporting_actress) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, supporting_actress: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Actor Principal</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.main_actor) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, main_actor: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Actor secundario</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.secondary_actor) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, secondary_actor: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Nombre completo</Form.Label>
                  <Form.Control type="text" placeholder="Escriba el nombre..." style={{ borderColor: (errors.full_name) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, full_name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Cédula de identidad </Form.Label>
                  <NumberFormat className="form-control" style={{ borderColor: (errors.identity_card) ? "rgb(200, 55, 55)" : "#e7eaf3" }} format="###-#######-#" placeholder="###-#######-#"
                    mask="_" onChange={(e) => setForm({ ...form, identity_card: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Teléfono de contacto</Form.Label>
                  <NumberFormat className="form-control" style={{ borderColor: (errors.telephone_contact) ? "rgb(200, 55, 55)" : "#e7eaf3" }} format="(###) ###-####" placeholder="(###) ###-####"
                    mask="_" onChange={(e) => setForm({ ...form, telephone_contact: e.target.value })} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control type="text" placeholder="Escriba su correo electrónico..." style={{ borderColor: (errors.email) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                    onChange={(e) => setForm({ ...form, email: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>

          </Form>
          <div style={{ textAlign: "center", display: (!done) ? "none" : "block" }}>
            <i className="far fa-paper-plane" style={{ fontSize: 50, marginBottom: 20, marginTop: 20, color: "#3b4850" }}></i>
            <p style={{ fontSize: 18, color: "#3b4850" }}>El formulario fue enviado correctamente</p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: (done) ? "none" : "block", textAlign: "right" }}>
          <Button variant="secondary" onClick={() => setShow({ ...show, edit: false })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledAdd}><i className="far fa-paper-plane"></i>&nbsp;&nbsp;Enviar</Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Page;