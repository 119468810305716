import { vimeo } from '../constants/ActionTypes';

const INITIAL_STATE = {
    list: [],
    selected: null
}

const vimeosActionsFunc = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case vimeo.GET_VIMEOS: {
            return { ...state, list: action.payload }
        }
        case vimeo.GET_VIMEO: {
            return { ...state, selected: action.payload }
        }
        default:
            return state;
    }
}

export default vimeosActionsFunc