import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import BoardDirectors from "../../modules/page/board-directors";
import Contact from "../../modules/page/contact";
import Footer from "../../modules/page/footer";
import Header from "../../modules/page/header";
import Objectives from "../../modules/page/objectives";
import "./style.css";
import { Link } from "react-router-dom";

function Page(props) {

  return (
    <>
      <Header></Header>
      <section id="a-top">
        <Container>
          <ul className="nav-bar">
            <li><Link to="/">Home</Link> <span>&gt;</span> <Link to="#">Adocine</Link></li>
          </ul>
        </Container>
      </section>
      <section id="a-history">
        <Container>
          <Row>
            <Col>
              <Image src={"../assets/chairs.png"} />
            </Col>
            <Col>
              <h2 className="title-page">Historia</h2>
              <p>Desde 1963 hasta 1990, Las producciones de directores dominicanos.</p>
              <p>Básicamente se reducen a siete largometrajes rodados en la isla y dos en Francia. En este periodo también se ruedan 60 cortometrajes y 27 documentales.</p>
              <p>El director Franklin Domínguez, Junto al guionista y actor Camilo Carrau, se unen para hacer la película “La Silla”. Un largometraje de 90 minutos con un monólogo de un solo actor. Que relata la historia de un prisionero mientras permanece sentado en una silla eléctrica. La única copia existente esta en muy mal estado y se ha dado por perdida en su forma integral, tal como fuese exhibida.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Una película muy original, que representaba la modernidad del cine dominicano luego de tres décadas de represión cultural. Un filme de corte teatral y lleno de simbolismo, donde Camilo Carrau frente a la dirección fotográfica de Clark Johnson recreaba toda una época de opresión dominicana bajo una férrea dictadura.</p>
              <p>La invitación a la gala premiere se hizo un 24 de enero del año 1963 en el exclusive teatro elite ubicado en la calle Pasteur de la ciudad de Santo Domingo.</p>
              <p>Los premios ADOCINE, con el propósito de celebrar y homenajear lo mejor de nuestra cinematografía cada año, es una iniciativa creada por el Consejo de Directores de la Asociación Dominicana de Profesionales de Cine, desde el año 2013.</p>
            </Col>
            <Col>
              <Image src={"../assets/rolleicord.png"} />
            </Col>
          </Row>
        </Container>
      </section>
      <Objectives></Objectives>
      <BoardDirectors></BoardDirectors>
      <section id="a-why-member">
        <Container>
          <h2 className="title-page">¿Por qué ser miembro de Adocine?</h2>
          <p className="decription"><strong>ADOCINE</strong> es la asociación de mayor alcance y prestigio en todas las esferas del cine dominicano, donde convergen los más prestigiosos profesionales de la industria cinematográfica de la Republica Dominicana:</p>
          <Row>
            <Col>
              <ul>
                <li><span>01</span><p>Representación En el CIPAC.</p></li>
                <li><span>03</span><p>Derecho a voto en los premios La Silla.</p></li>
                <li><span>05</span><p>Derecho a optar por un cargo en la directiva.</p></li>
              </ul>
            </Col>
            <Col>
              <ul>
                <li><span>02</span><p>Asistencia Gratuita a los conversatorios, Talleres y múltiples actividades organizadas por adocine.</p></li>
                <li><span>04</span><p>Participación y derecho a voto en las asambleas bi-anual de la asociación.</p></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <Contact nav={false}></Contact>
      <Footer></Footer>
    </>
  );
}

export default Page;
