import React from 'react';
import { Col, Container, Row, Image } from "react-bootstrap";
import "./style.css";

function Page(props) {

    return (
        <section id="h-board-directors">
            <Container>
                <h2 className="title-page">Junta Directiva</h2>
                <Row>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/hans_garcia.webp"} />
                        <h3>Hans García</h3>
                        <label>Presidente</label>
                    </Col>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/marcel_fondeur.webp"} />
                        <h3>Marcel Fondeur</h3>
                        <label>Vicepresidente</label>
                    </Col>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/danilo_reynoso.webp"} />
                        <h3>Danilo Reynoso</h3>
                        <label>Secretario</label>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/omar_de_la_cruz.webp"} />
                        <h3>Omar De La Cruz</h3>
                        <label>Tesorero</label>
                    </Col>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/elizabeth_jimenez.webp"} />
                        <h3>Elizabeth Jimenez</h3>
                        <label>Vocal</label>
                    </Col>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/gina_giudicelli.webp"} />
                        <h3>Gina Giudicelli</h3>
                        <label>Vocal</label>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/william_liriano.webp"} />
                        <h3>William Liriano</h3>
                        <label>Vocal</label>
                    </Col>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/irene_abreu.webp"} />
                        <h3>Irene Abreu</h3>
                        <label>Vocal</label>
                    </Col>
                    <Col md={4}>
                        <Image src={"../assets/board_directors/hector_then.webp"} />
                        <h3>Hector Then</h3>
                        <label>Vocal</label>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Page;