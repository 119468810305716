import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import UserActions from '../../redux/actions/Users';
import { withRouter } from 'react-router-dom';

const Login = ({ users, setAuthentication, history }) => {

    const [form, setForm] = useState({}),
        [errors, setErrors] = useState({})

    useEffect(() => {
        if (users.authUser) {
            history.push("/votes")
        }
    }, [users.authUser]);

    const

        /**
         * Handled add new user
         */
        handledLogin = () => {
            if (handleValidate("login")) {
                setAuthentication(form, history)
            }
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = (type) => {
            setErrors({})
            if (type === "login") {
                if (!form.username) {
                    setErrors({ username: true });
                    return false;
                } else if (!form.password) {
                    setErrors({ password: true });
                    return false;
                }
            } else {
                if (!form.email) {
                    setErrors({ email: true });
                    return false;
                }
            }
            return true;
        }

    return (<Page handledLogin={handledLogin} form={form} setForm={setForm} errors={errors} />)
}

const mapStateToProps = ({ users }) => ({
    users
});

const mapDispatchToProps = () => ({
    ...UserActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Login));