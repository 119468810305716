import React from "react";
import Footer from "../../modules/page/footer";
import Header from "../../modules/page/header";
import "./style.css";
import { Button, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import NumberFormat from "react-number-format";

function Page(props) {

  const { errors, setForm, form, handledUpdate, handledUpdatePassword } = props

  return (
    <>
      <Header></Header>
      <section id="h-profile">
        <Container>
          <Tab.Container defaultActiveKey="first" className="tab-options">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Mi Perfil</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Cambiar contraseña</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <h2 className="title-page">Mi Perfil</h2>
                    <Form>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Nombre Completo</Form.Label>
                            <Form.Control type="text" style={{ borderColor: (errors.full_name) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba su nombre completo..." value={form.full_name} onChange={(e) => setForm({ ...form, full_name: e.target.value })} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Cédula</Form.Label>
                            <NumberFormat className="form-control" style={{ borderColor: (errors.identification_card) ? "rgb(200, 55, 55)" : "#e7eaf3" }} format="###-#######-#" placeholder="###-#######-#" mask="_" value={form.identification_card} onChange={(e) => setForm({ ...form, identification_card: e.target.value })} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label>Correo electrónico</Form.Label>
                        <Form.Control type="email" value={form.username} disabled />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Teléfono</Form.Label>
                            <NumberFormat className="form-control" format="(###) ###-####" placeholder="(###) ###-####" mask="_" value={form.phone} onChange={(e) => setForm({ ...form, phone: e.target.value })} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Móvil</Form.Label>
                            <NumberFormat className="form-control" format="(###) ###-####" placeholder="(###) ###-####" mask="_" value={form.mobile} onChange={(e) => setForm({ ...form, mobile: e.target.value })} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Código para películas</Form.Label>
                            <Form.Control type="email" value={form.vimeo_code} disabled />
                          </Form.Group>
                        </Col>
                        <Col></Col>
                      </Row>
                      <div>
                        <Button className="btn-adocine" onClick={handledUpdate}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar Cambios</Button>
                      </div>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <h2 className="title-page">Cambiar contraseña</h2>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Contraseña Actual</Form.Label>
                        <Form.Control type="password" style={{ borderColor: (errors.password) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba la contraseña..." value={form.password} onChange={(e) => setForm({ ...form, password: e.target.value })} />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Nueva contraseña</Form.Label>
                        <Form.Control type="password" style={{ borderColor: (errors.new_password) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba la contraseña..." value={form.new_password} onChange={(e) => setForm({ ...form, new_password: e.target.value })} />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Repita la nueva contraseña</Form.Label>
                        <Form.Control type="password" style={{ borderColor: (errors.rep_new_password) ? "rgb(200, 55, 55)" : "#e7eaf3" }} placeholder="Escriba la contraseña..." value={form.rep_new_password} onChange={(e) => setForm({ ...form, rep_new_password: e.target.value })} />
                      </Form.Group>
                      <div>
                        <Button className="btn-adocine" onClick={handledUpdatePassword}><i className="fas fa-save"></i>&nbsp;&nbsp;Guardar Cambios</Button>
                      </div>
                    </Form>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Page;
