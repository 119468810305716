import React from "react";
import "./style.css";
import Header from "../../modules/page/header";
import Footer from "../../modules/page/footer";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function Page(props) {

  const { movies } = props

  return (
    <>
      <Header></Header>
      <section id="n-top">
        <Container>
          <ul className="nav-bar">
            <li style={{ textAlign: "left" }}><Link to="/">Home</Link> <span>&gt;</span> <Link to="#">Votación Silla {new Date().getFullYear()}</Link> <span>&gt;</span> <Link to="#">Nominaciones</Link></li>
          </ul>
          <div>
            <h2 className="title-page">NOMINACIONES</h2>
            <p className="decription">¿Disfrutaste de alguna película y quiéres votar por ella?</p>
            <Link className="button" to="/votes">Votar</Link>
          </div>
        </Container>
        <Container id="n-vote">
          <div className="movies">
            {
              movies.list.map((row, key) => {
                return (
                  <div className="movie" key={key}>
                    <div className="cover">
                      <div className="text">
                        <h3>{row.name}</h3>
                        <p>Género: {row.name}.</p>
                        <p>Director: {row.director}.</p>
                        <p>Productor: {row.producer}</p>
                        <p>Guión: {row.script}</p>
                        <p>Género: {row.gender}</p>
                        <Link className="button" to={"/movie/" + row.movie_id}>Ver más</Link>
                      </div>
                    </div>
                    <Image src={(row.cover) ? row.cover : "../../assets/cover.webp"} />
                  </div>
                )
              })
            }
          </div>
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Page;