import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import VimeosActions from '../../redux/actions/Vimeo';
import VimeoCodesActions from '../../redux/actions/VimeoCode';
import { withRouter } from 'react-router-dom';

const Player = ({ users, vimeo, getVimeos, getVimeoCode, history }) => {

  const [video, setVideo] = useState(null),
    [codeValidate, setCodeValidate] = useState({ code: "", show: false, video: null }),
    [errors, setErrors] = useState({})

  useEffect(() => {
    if (!users.authUser) history.push("/")
  }, [users.authUser, history])

  useEffect(() => {
    getVimeos()
  }, [getVimeos])

  /**
   * Handled validate video
   * @param {*} video 
   */
  const handledVideo = (video) => {
    setVideo(null)
    setCodeValidate({ ...codeValidate, show: true, video })
  }

  /**
  * Validate the form
  * @returns true or false
  */
  const handleValidate = () => {
    setErrors({})
    if (!codeValidate.code) {
      setErrors({ code: true });
      return false;
    }
    return true;
  }

  /**
   * Handled check code
   */
  const handledCheck = () => {
    if (handleValidate()) {
      getVimeoCode(codeValidate.code, codeValidate.video, () => {
        setVideo(codeValidate.video)
        setCodeValidate({ code: "", show: false, video: null })
      })
    }
  }

  return (
    <Page vimeo={vimeo} video={video} handledVideo={handledVideo} codeValidate={codeValidate}
          setCodeValidate={setCodeValidate} errors={errors} handledCheck={handledCheck} />
  );
}

const mapStateToProps = ({ users, vimeo }) => ({
  users, vimeo
});

const mapDispatchToProps = () => ({
  ...VimeosActions,
  ...VimeoCodesActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Player));