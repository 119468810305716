import React, { useState } from "react";
import "./style.css";
import Header from "../../modules/page/header";
import Footer from "../../modules/page/footer";
import { Button, Col, Container, Form, Image, InputGroup, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Page(props) {

  const { vimeo, video, handledVideo, codeValidate, setCodeValidate, errors, handledCheck } = props,
   [filterText, setFilterText] = useState(''),
    filteredItems = (vimeo?.list ? vimeo?.list : []).filter(item => {
      return (
        (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) 
        ) ? true : false;
    });

  return (
    <>
      <Header></Header>
      <section id="mv-top">
        <Container>
          <ul className="nav-bar">
            <li><Link to="/">Home</Link> <span>&gt;</span> <Link to="#">Películas</Link></li>
          </ul>
          <div>
            <h2 className="title-page">PELÍCULAS</h2>
          </div>
        </Container>
      </section>
      <section id="bd-player">
        <Container>
          {
            video && 
            <div className="video-wrapper">
              <div style={{ padding: "56.25% 0 0 0", position: "relative", background: "#000" }}>
                <iframe title="video" src={`https://player.vimeo.com/video/${video.code}?h=&title=0&byline=0&portrait=0`}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} frameBorder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
              <h5>{video.title}</h5>
            </div>
          }
          <div className="search">
            <InputGroup>
              <InputGroup.Text id="btnGroupAddon"><i className="fas fa-search"></i></InputGroup.Text>
              <Form.Control type="text" placeholder="Buscar..." value={filterText} onChange={(e) => setFilterText(e.target.value)}
              />
            </InputGroup>
          </div>
          <Row>
            {
              filteredItems && filteredItems.map((row, key) => {
                return (
                  <Col md={4} key={key} onClick={() => handledVideo(row)}>
                    <div className="video">
                      <Image src={`${process.env.REACT_APP_URL_BASE}vimeos/${row.thumbnail}`} />
                      <label>{row.title}</label>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </section>
      <Footer></Footer>

      <Modal show={codeValidate.show} size={"sm"} backdrop="static" onHide={() => setCodeValidate({ ...codeValidate, show: false, code: "" })} id="md-check-code">
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Código de verificación</Form.Label>
              <Form.Control type="text" size="lg" style={{ borderColor: (errors.code) ? "rgb(200, 55, 55)" : "#e7eaf3" }}
                placeholder="##########"
                value={codeValidate.code} onChange={(e) => setCodeValidate({ ...codeValidate, code: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCodeValidate({ ...codeValidate, show: false, code: "" })}><i className="fas fa-times"></i>&nbsp;&nbsp;Cerrar</Button>
          <Button variant="success" onClick={handledCheck}><i className="fas fa-search"></i>&nbsp;&nbsp;Verificar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Page;