import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import UserActions from '../../redux/actions/Users';
import { withRouter } from 'react-router-dom';

const ForgetPassword = ({ users, history, setResetPassword }) => {

    const [form, setForm] = useState({}),
        [errors, setErrors] = useState({}),
        [send, setSend] = useState(false)

    useEffect(() => {
        if (users.authUser) {
            history.push("/profile")
        }
    }, [users.authUser]);

    const

        /**
         * Handled forget password
         */
        handledForgetPassword = () => {
            if (handleValidate()) {
                setResetPassword(form.email, () => {
                    setSend(true)
                })
            }
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = (type) => {
            setErrors({})
            if (!form.email) {
                setErrors({ email: true });
                return false;
            }
            return true;
        }

    return (<Page form={form} setForm={setForm} errors={errors} handledForgetPassword={handledForgetPassword} send={send} />)
}

const mapStateToProps = ({ users }) => ({
    users
});

const mapDispatchToProps = () => ({
    ...UserActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(ForgetPassword));