import React from 'react';
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";

function Page(props) {

    const { form, setForm, errors, handleSendForm } = props;

    return (
        <section id="h-contact">
            <Container>
                <h2 className="title-page">Contacto</h2>
                <Row>
                    <Col xs={12} md={6}>
                        <strong className="contac-strong">Dirección</strong>
                        <p>Calle General Domingo Mallol No. 1, El Millón, Santo Domingo, Distrito Nacional, RD</p>
                        <strong className="contac-strong">Teléfono</strong>
                        <p>809-328-3669</p>
                        <strong className="contac-strong">Dirección de correo electrónico</strong>
                        <p>adocineorg@gmail.com</p>

                        <strong className="contac-strong" style={{ marginTop: 40 }}>Síguenos</strong>
                        <ul className="social-media">
                            <li><Link to={{ pathname: "https://www.facebook.com/adocinerd/" }} target="_blank"><i className="fab fa-facebook-f" /></Link></li>
                            <li><Link to={{ pathname: "https://www.instagram.com/Adocine/" }} target="_blank"><i className="fab fa-instagram" /></Link></li>
                            <li><Link to={{ pathname: "https://mobile.twitter.com/ADOCINE" }} target="_blank"><i className="fab fa-twitter" /></Link></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ textAlign: "center", marginTop: 30, display: (form.send) ? "block" : "none" }}>
                            <i className="far fa-paper-plane" style={{ fontSize: 50, marginBottom: 20, color: "#fff" }}></i>
                            <p style={{ display: "block", margin: "0 auto", minWidth: 500 }}>
                                Su formulario fue enviado correctamente, en la brevedad posible estaremos respondiendo su solicitud. <br /><br /> ¡Gracias por preferirnos!
                            </p>
                        </div>
                        <Form style={{ display: (form.send) ? "none" : "block" }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Dirección de correo electrónico</Form.Label>
                                <Form.Control type="email" placeholder="Escriba la dirección de correo electrónico..." 
                                    style={{ borderColor: (errors.email) ? "rgb(200, 55, 55)" : "#e7eaf3", backgroundColor: (errors.email) ? "rgb(255, 219, 219)" : "white" }}
                                    onChange={(e) => setForm({ ...form, email: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Asunto</Form.Label>
                                <Form.Control type="text" placeholder="Escriba el asunto..." 
                                    style={{ borderColor: (errors.subject) ? "rgb(200, 55, 55)" : "#e7eaf3", backgroundColor: (errors.subject) ? "rgb(255, 219, 219)" : "white" }}
                                    onChange={(e) => setForm({ ...form, subject: e.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Comentario</Form.Label>
                                <Form.Control as="textarea" rows={5} placeholder="Escriba su comentario..." 
                                    style={{ borderColor: (errors.comment) ? "rgb(200, 55, 55)" : "#e7eaf3", backgroundColor: (errors.comment) ? "rgb(255, 219, 219)" : "white" }}
                                    onChange={(e) => setForm({ ...form, comment: e.target.value })} />
                            </Form.Group>
                            <div style={{ textAlign: "center" }}>
                                <Button variant="primary" type="button" className="btn-adocine" onClick={handleSendForm}>
                                    <i className="far fa-paper-plane"></i>&nbsp;&nbsp;Enviar correo
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Page;