import React, { useEffect, useState } from 'react';
import Page from './page';
import moment from 'moment';
import { connect } from 'react-redux';
import UserActions from '../../redux/actions/Users';
import PositionsActions from '../../redux/actions/Positions';
import VimeoCodeActions from '../../redux/actions/VimeoCode';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Members = ({ users, positions, getUsers, getMember, setUser, setRemoveUser, setUpdateMember, getPositions, setClearUser, setResetVimeoCode }) => {

    const [show, setShow] = useState({ add: false, edit: false }),
        [enable, setEnable] = useState(true),
        [uid, setUid] = useState(null),
        [form, setForm] = useState({ mobile: "", phone: "", identification_card: "", active: 1 }),
        [errors, setErrors] = useState({}),
        [options, setOptions] = useState([])

    useEffect(() => {
        getUsers("member")
        getPositions()
    }, []);

    useEffect(() => {
        if (positions.list) {
            let list = []
            positions.list.map(row => {
                list.push({ value: row.position_id, label: row.name })
            })
            setOptions(list)
        }
    }, [positions.list]);

    useEffect(() => {
        if (users.selectedUser) {
            let positions = users.selectedUser.positions.split(","), list = [];
            options.map(row => {
                if (positions.includes(String(row.value))) {
                    list.push(row)
                }
            })
            setForm({
                code: users.selectedUser.code,
                full_name: users.selectedUser.full_name,
                username: users.selectedUser.username,
                email: users.selectedUser.email,
                identification_card: users.selectedUser.identification_card,
                mobile: users.selectedUser.mobile,
                phone: users.selectedUser.phone,
                positions: list,
                active: parseInt(users.selectedUser.active)
            })
            setShow({ ...show, edit: true })
        }
    }, [users.selectedUser]);

    useEffect(() => {
        if(!show.add && !show.edit) {
            setClearUser()
            setForm({ full_name: "", username: "", email: "", password: "", identification_card: "", phone: "", mobile: "", active: 1 })
        }
    }, [show.edit, show.add]);

    const

        /**
         * Handled add new user
         */
        handledAdd = () => {
            let positions = []
            if (handleValidate()) {
                form.positions.map(row => {
                    positions.push(row.value)
                })
                setUser({
                    code: form.code,
                    full_name: form.full_name,
                    username: form.email,
                    email: form.email,
                    user_type: "member",
                    positions: positions,
                    identification_card: form.identification_card,
                    phone: form.phone,
                    mobile: form.mobile
                }, "member")
                setShow({ ...show, add: false })
            }
        },

        /**
         * Handled update user by uid
         */
        handledUpdate = () => {
            let positions = []
            if (handleValidate()) {
                form.positions.map(row => {
                    positions.push(row.value)
                })
                setUpdateMember(uid, {
                    full_name: form.full_name,
                    email: form.email,
                    password: form.password,
                    positions: positions,
                    identification_card: form.identification_card,
                    phone: form.phone,
                    mobile: form.mobile,
                    active: parseInt((form.active) ? 1 : 0)
                }, "member")
                setShow({ ...show, edit: false })
            }
        },

        /**
         * Handled show edit
         */
        handledShowEdit = (uid) => {
            setUid(uid)
            getMember(uid)
        },

        /**
         * Handled remove user
         */
        handledRemove = (uid) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Eliminar Usuario</h3>
                            <p>¿Realmente deseas eliminar este usuario?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setRemoveUser(uid, "member", onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            })
        },

        /**
         * handled reset vimeo code
         */
        handledResetVimeoCode = (row) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3><i className="fas fa-exclamation-circle"></i>&nbsp;Reiniciar Código</h3>
                            <p>¿Realmente deseas reiniciar el código de vimeo?</p>
                            <div className="text-center">
                                <button className="btn btn-secondary" onClick={onClose} style={{ float: "left" }}><i className="fas fa-times"></i>&nbsp;Cancel</button>
                                <button className="btn btn-success" onClick={() => setResetVimeoCode(row.vimeo_code, onClose)} style={{ float: "right" }}><i className="fas fa-check"></i>&nbsp;Aceptar</button>
                            </div>
                        </div>
                    )
                }
            })
        },

        /**
         * Validate the form
         * @returns true or false
         */
        handleValidate = () => {
            setErrors({})
            if (!form.code) {
                setErrors({ code: true });
                return false;
            } else if (!form.full_name) {
                setErrors({ full_name: true });
                return false;
            } else if (!form.email || !form.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setErrors({ email: true });
                return false;
            } else if (!form.positions) {
                setErrors({ positions: true });
                return false;
            }
            return true;
        };

    const columns = [
        {
            name: 'ID', selector: row => row.user_id, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span>{row.user_id}</span>
            )
        }, {
            name: 'Nombre', selector: row => row.full_name, minWidth: '20%', maxWidth: '20%', sortable: true, cell: row => (
                <span>{row.full_name}</span>
            )
        }, {
            name: 'Email', selector: row => row.email, minWidth: '35%', maxWidth: '40%', sortable: true, cell: row => (
                <span>{row.email}</span>
            )
        }, {
            name: 'Estado', selector: row => row.active, minWidth: '10%', maxWidth: '10%', sortable: true, cell: row => (
                <span style={{ color: ((parseInt(row.active)) ? "#31821b" : "rgb(200, 55, 55)") }}>{(parseInt(row.active)) ? "Activo" : "Desactivo"}</span>
            )
        }, {
            name: 'Creado', selector: row => row.created, minWidth: '12%', maxWidth: '12%', sortable: true, cell: row => (
                <span>{moment(row.created).format("DD/MM/YYYY")}</span>
            )
        }, {
            name: '', minWidth: '10%', maxWidth: '10%', cell: row => (
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-between" }}>
                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Reiniciar Código de Vimeo"
                        style={{ margin: "0 auto" }} onClick={() => handledResetVimeoCode(row)}><i className="fas fa-sync-alt pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Editar Usuario"
                        style={{ margin: "0 auto" }} onClick={() => handledShowEdit(row.user_id)}><i className="far fa-edit pointer"></i></button>

                    <button className="btn btn-secondary btn-sm pointer" type="button" data-place="bottom" data-tip="Eliminar Usuario"
                        style={{ margin: "0 auto" }} onClick={() => handledRemove(row.user_id)}><i className="far fa-trash-alt pointer"></i></button>
                </div>
            )
        }
    ], paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    return (
        <Page columns={columns} paginationOptions={paginationOptions} users={users} show={show} setShow={setShow} form={form} errors={errors}
            options={options} positions={positions} setForm={setForm} handledAdd={handledAdd} handledRemove={handledRemove} enable={enable}
            setEnable={setEnable} handledUpdate={handledUpdate} />
    );
}

const mapStateToProps = ({ users, positions }) => ({
    users,
    positions
});

const mapDispatchToProps = () => ({
    ...UserActions,
    ...VimeoCodeActions,
    ...PositionsActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(Members));