import React from "react";
import "./style.css";
import moment from 'moment';
import Header from "../../modules/page/header";
import Footer from "../../modules/page/footer";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Page(props) {

  const { movies } = props

  return (
    <>
      <Header></Header>
      <section id="mv-top">
        <Container>
          <ul className="nav-bar">
            <li><Link to="/">Home</Link> <span>&gt;</span> <Link to="/nominations">Nominaciones</Link> <span>&gt;</span> <Link to="#">Película Pulso</Link></li>
          </ul>
          <div>
            <h2 className="title-page">PELÍCULAS</h2>
          </div>
        </Container>
      </section>

      <section id="mv-movie">
        <Container>
          <Row>
            <Col>
              <Image src={(movies.selected && movies.selected.cover) ? movies.selected.cover : "../../assets/cover.webp"} />
            </Col>
            <Col>
              <h2 className="title-page">{(movies.selected && movies.selected.name) ? movies.selected.name : "-"}</h2>
              <p><strong>Director:</strong>{(movies.selected && movies.selected.director) ? movies.selected.director : "-"}</p>
              <p><strong>Productor:</strong>{(movies.selected && movies.selected.producer) ? movies.selected.producer : "-"}</p>
              <p><strong>Guión:</strong>{(movies.selected && movies.selected.script) ? movies.selected.script : "-"}</p>
              <p><strong>Género</strong>{(movies.selected && movies.selected.gender) ? movies.selected.gender : "-"}</p>
              <p><strong>Cast:</strong>{(movies.selected && movies.selected.cast) ? movies.selected.cast : "-"}</p>
              <p><strong>Estreno:</strong>{(movies.selected && movies.selected.release_date) ? moment(movies.selected.release_date).format("DD/MM/YYYY") : "-" }</p>
            </Col>
          </Row>
          {
            (movies.selected && movies.selected.trailer) ?
              <div>
                <h2 className="title-page">TRAILER</h2>
                <div className="iframe">
                  <iframe src={"https://www.youtube.com/embed/" + movies.selected.trailer} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div> : null
          }
        </Container>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Page;