import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import UserActions from '../../redux/actions/Users';
import { withRouter } from 'react-router-dom';

const GenerateCode = ({ users, generateCode }) => {

    const [form, setForm] = useState({ code:"", login: false  })

    useEffect(() => {
        if(users?.authUser) {
            setForm({ login: true, code: users?.authUser?.vimeo_code })
        }
    }, [users?.authUser])

    const handledGenerateCode = () => {
        generateCode((resp) => {
            setForm({ ...form, code: resp.code })
        })
    }

    return (<Page handledGenerateCode={handledGenerateCode} form={form} />)
}

const mapStateToProps = ({ users }) => ({
    users
});

const mapDispatchToProps = () => ({
    ...UserActions
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps())(GenerateCode));